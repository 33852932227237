<template>
	<v-layout v-if="getSite">
		<v-row>
			<v-flex col-12 col-xl-6 col-lg-7 d-none d-md-flex info align-center justify-center>
				<v-container>
					<div class="pa-10">
						<v-row class="justify-center">
							<v-col cols="8" xl="5"> </v-col>
						</v-row>
					</div>
				</v-container>
			</v-flex>
			<v-flex col-12 col-xl-6 col-lg-5 d-flex align-center>
				<v-container>
					<div class="pa-7 pa-sm-12">
						<v-row>
							<v-col cols="12" xl="6" lg="9">
								<v-img
									contain
									class="logo"
									:lazy-src="getSite.companyLogo"
									:src="getSite.companyLogo"
									max-height="75"
									max-width="150"
								></v-img>
								<v-col>
									<transition name="fade" mode="out-in">
										<router-view></router-view>
									</transition>
								</v-col>
							</v-col>
						</v-row>
					</div>
				</v-container>
			</v-flex>
		</v-row>
	</v-layout>
</template>

<style lang="scss">
.logo {
	.v-image__image {
		background-position: left center !important;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { INIT_COMPANY } from '@/store/modules/company.module';

export default {
	name: 'AuthBase',
	computed: {
		...mapGetters(['isDark', 'getSite']),
		...mapState({
			errors: (state) => state.auth.errors,
		}),
	},
	created() {
		this.$vuetify.theme.dark = this.isDark;
		this.initCompany();
	},
	methods: {
		...mapActions({
			initCompany: INIT_COMPANY,
		}),
	},
};
</script>
